<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row>
        <b-col lg="6">
          <b-card
            class="text-center"
            :title="'tipo orden'"
          >
            <b-row>
              <b-col md="2">
                <b-form-radio
                  v-model="order_type"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="DEPOSIT"
                >
                  Deposito
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="order_type"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="WITHDRAW"
                >
                  Saque
                </b-form-radio>
              </b-col>

            </b-row>

          </b-card>

          <b-card
            class="text-center"
            :title="'menor a'"
          >

            <b-input-group>
              <b-form-input
                v-model="qty"
                type="number"
                min="0"
                max="100"
                step="1"
              />
            </b-input-group></b-card>
        </b-col>

        <b-col lg="6">
          <div class="row">
            <div class="col">
              <b-card title="Usuario Activo entre">
                <template>
                  <b-form-datepicker
                    v-model="from"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                  />
                  <b-form-datepicker
                    v-model="to"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                  />
                </template>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            @click="search"
          >
            Buscar
          </b-button>
        </b-col>

      </b-row>
      <b-row v-if="orders_stats.length>0" id="cardo">
        <!-- <b-row class="w-100 mb-2">
            <b-col>Nome</b-col>
            <b-col>Depositos</b-col>
            <b-col>Saques</b-col>
            <b-col>Resultado</b-col>
          </b-row>
        <div v-for="transaction, idx in transactionsObj" class="w-100">
          <b-row>
            <b-col>{{ docs.filter(d=>d.user_id == idx)[0].value }}</b-col>
            <b-col>{{ transaction.deposits  | brl}}</b-col>
            <b-col>{{ transaction.withdrawals  | brl}}</b-col>
            <b-col>{{ transaction.deposits - transaction.withdrawals | brl}}</b-col>
          </b-row>
        </div>
         -->
        <div
          v-if="tableItems.length > 0"
          id="export-button"
        >
          <json-excel :data="tableItems">
            <feather-icon
              icon="SaveIcon"
              size="40"
              class="ml-2 text-success pointer"
            />
          </json-excel>
        </div>

        <b-table
          striped
          hover
          :items="tableItems"
        />
      </b-row>
      <!-- <withdraws-list
        :orders="withdraw_orders.data"
        :context-actions="'admin'"
        @emitSidebar="openTransactionSidebar"
      /> -->
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'
import JsonExcel from "vue-json-excel";

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio, BFormDatepicker, BTable, BButton, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BTable,
    BCard,
    BFormDatepicker,
    BCardText,
    BLink,
    underscore: _,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    LogoLoaderAnimation,
    BFormRadio,
    BCol,
    BRow,
    JsonExcel

  },
  data() {
    return {
      from: new Date(new Date().getTime() - (3 * 60 * 60 * 1000)).toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (21 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      currentPage: 1,
      tableItems: [],
      transactionObj: null,
      transactionStatus: 0,
      fields: [
        { key: 'Index', sortable: true },
        { key: 'Bet', sortable: true },
        { key: 'Win', sortable: true },
        { key: 'Resultado', sortable: true },
      ],
      statusName: ['PENDING', 'CONFIRMED', 'CANCELED', 'PROCESSING'],
      orders_stats: [],
      usersToName: [],
      docs: false,
      totalBet: 0,
      totalWin: 0,
      totalGgr: 0,
      qty: 1,
      order_type: 'DEPOSIT',
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  watch: {
    order_type() {
      this.search()
    },
    from() {
      this.search()
    },
    to() {
      this.search()
    },
  },
  created() {
    this.search()
  },
  methods: {

    get_info(type, userId) {
      const items = this.user_info.filter(d => d.user_id == userId && d.type == type)
      if (items.length > 0) {
        return items[0].value
      }
      return ''
    },
    process() {
      const list = []

      this.orders_stats.forEach(order => {
        list.push({
          user_id: order.user_id,
          order_type: order.order_type,
          updated_at: order.updated_at,
          qty: order.qty,
          total_amount: order.total_amount,
          fullname: this.get_info('FULLNAME', order.user_id),
          email: this.get_info('EMAIL', order.user_id),
          phone: this.get_info('PHONE', order.user_id),
          cpf: this.get_info('CPF', order.user_id),
        })
      })

      this.tableItems = list
    },
    search() {
      this.loading = true
      this.$apollo.query(
        {
          query: gql`query orders_stats($order_type:Mixed!, $from:Mixed!, $to:Mixed!, $qty:Mixed!) {
  orders_stats(where:{column:ORDER_TYPE, operator:EQ, value: $order_type, AND:{
    column:UPDATED_AT, operator:GTE, value: $from, AND:{
      column: UPDATED_AT, operator: LTE, value: $to,
      AND:{
        column: QTY, operator:LTE, value: $qty
      }
    } 
  }}){
    user_id
    order_type
    updated_at
    qty
    total_amount
  }
}`,
          client: 'cashioClient',
          variables: {
            order_type: this.order_type,
            from: this.from,
            to: this.to,
            qty: this.qty,
          },
        },
      ).then(data => {
        console.log('data', data)
        this.orders_stats = data.data.orders_stats
        this.loading = false
        this.search_identity(this.orders_stats.map(o => o.user_id))
      })
    },

    search_identity(usersIds) {
      this.loading = true
      this.$apollo.query(
        {
          query: gql`
          query get_users_doc($user_ids:[ID!]!){
  get_users_doc(user_ids:$user_ids){
    user_id
    type
    value
    
  }
}

          `,
          client: 'identityClient',
          variables: {
            user_ids: usersIds,
          },
        },
      ).then(data => {
        console.log('data', data)
        this.user_info = data.data.get_users_doc
        this.process()
        this.loading = false
      })
    },
  },
}
</script>

<style>
   .floater{
    /* position: absolute; */
    z-index: 99;
  }
  .data-table tbody{
    padding-bottom: 30px;
  }
  #export-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #cardo{
    position:relative
  }
</style>
